import $ from 'jquery'

jQuery(document).ready(function ($) {
    var currentPageUrl = window.location.href;
    $('.hero__submenu__inner a').each(function () {
        var anchorUrl = $(this).attr('href');
        if (anchorUrl === currentPageUrl) {
            $(this).addClass('current-page-link');
        }
    });

    $(".js-hero-mobile-nav").on('click', function () {
        $(".hero__submenu__mobile-inner").toggleClass("active");
    });
    // Remove the active class if clicking anywhere outside .hero__submenu__mobile-inner
    $(document).on('click', function (event) {
        if (!$(event.target).closest('.hero__submenu__mobile-inner, .js-hero-mobile-nav').length) {
            $(".hero__submenu__mobile-inner").removeClass("active");
        }
    });
    // Prevent click event from propagating to the document when clicking inside .hero__submenu__mobile-inner
    $(".hero__submenu__mobile-inner").on('click', function (event) {
        event.stopPropagation();
    });
});