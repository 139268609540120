import $ from 'jquery'

$(document).ready(function () {

    if ($(".map__holder").length) {
        var waitForMap = setInterval(function () {
            if ($('.wpgmp_tab_item').length) {
                // Bind click event
                $('.wpgmp_tab_item input[type="checkbox"]').bind('click', function () {
                    console.log('clicked');
                    if ($(this).is(':checked')) {
                        // Uncheck other checkboxes and remove their active class
                        $('.wpgmp_tab_item input[type="checkbox"]').not(this).each(function () {
                            $(this).prop('checked', false);
                            $(this).next().removeClass('active');
                        });

                        // Add active class to the current checkbox's next sibling
                        $(this).next().addClass('active');
                    } else {
                        $(this).next().removeClass('active');
                    }
                });

                // Check on page load
                var firstCheckedFound = false;
                $('.wpgmp_tab_item input[type="checkbox"]').each(function () {
                    if ($(this).is(':checked')) {
                        $(this).next().addClass('active');

                        // if (!firstCheckedFound) {
                        //     $(this).next().addClass('active');
                        //     firstCheckedFound = true;
                        // } else {
                        //     $(this).prop('checked', false).trigger('change');
                        //     $(this).next().removeClass('active');
                        // }
                    }
                });

                clearInterval(waitForMap);
            }
        }, 100);
    }
});