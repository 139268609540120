import $ from 'jquery';

$(document).ready(function () {
    function togglePopup(event) {
        event.stopPropagation();
        $(".simple-map__popup").hide();
        $(this).find(".simple-map__popup").toggle();
    }

    // Click event
    $(".simple-map__item").on('click', togglePopup);

    // Hover events
    $(".simple-map__item").on('mouseover', function () {
        $(".simple-map__popup").hide();
        $(".simple-map__item").css('z-index', '1');
        $(this).css('z-index', '2000');
        $(this).find(".simple-map__popup").show();
    });

    $(".simple-map__item").on('mouseout', function (event) {
        const popup = $(this).find(".simple-map__popup");
        $(this).css('z-index', '1');
        if (!$(event.relatedTarget).closest(".simple-map__popup, .simple-map__item").length) {
            popup.hide();
        }
    });

    $(".simple-map__popup").on('mouseenter', function () {
        $(this).show(); // Keep the popup visible while hovering over it
    });

    $(".simple-map__popup").on('mouseleave', function () {
        $(this).hide(); // Hide the popup when leaving it
    });

    $(document).on('click', function (event) {
        if (!$(event.target).closest('.simple-map__item, .simple-map__popup').length) {
            $(".simple-map__popup").hide(); // Hide all popups
        }
    });

    // Function to handle button state and image opacity
    function setActive(button) {
        $('.simple-map__filters button').removeClass('active').attr('aria-pressed', 'false');
        $(button).addClass('active').attr('aria-pressed', 'true');

        if (button.id === 'direct-tutoring') {
            $('.simple-map__direct-locations img').css('opacity', '1');
            $('.simple-map__item--circle').addClass('disable');
            $('.simple-map__item--star').removeClass('disable');
            $('.simple-map__rp-locations img').css('opacity', '.3');
        } else if (button.id === 'reading-partners') {
            $('.simple-map__rp-locations img').css('opacity', '1');
            $('.simple-map__direct-locations img').css('opacity', '.3');
            $('.simple-map__item--star').addClass('disable');
            $('.simple-map__item--circle').removeClass('disable');
        }
    }

    // Click event handlers
    $('#direct-tutoring').on('click', function () {
        setActive(this);
    });

    $('#reading-partners').on('click', function () {
        setActive(this);
    });

    // Key event handlers for accessibility
    $('.simple-map__filters button').on('keydown', function (event) {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            setActive(this);
        }
    });
});
