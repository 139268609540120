import $ from 'jquery'

jQuery(document).on('frmPageChanged', function (event, formId) {
    document.querySelectorAll('input[type="checkbox"], input[type="radio"]').forEach((input) => {
        const radiobuttons = document.querySelectorAll('.frm_forms input[type="radio"]');
        radiobuttons.forEach(radiobutton => {
            radiobutton.addEventListener('change', function () {
                $(".frm_radio").removeClass('grandparent-active');
                const grandparent = this.closest('.frm_radio');

                if (this.checked) {
                    grandparent.classList.add('grandparent-active');
                } else {
                    grandparent.classList.remove('grandparent-active');
                }
            });
        });
        const checkboxes = document.querySelectorAll('.frm_forms input[type="checkbox"]');
        checkboxes.forEach(checkbox => {
            checkbox.addEventListener('change', function () {
                const grandparent = this.closest('.frm_checkbox');

                if (this.checked) {
                    grandparent.classList.add('grandparent-active');
                } else {
                    grandparent.classList.remove('grandparent-active');
                }
            });
        });
    });
});

document.querySelectorAll('input[type="checkbox"], input[type="radio"]').forEach((input) => {
    const radiobuttons = document.querySelectorAll('.frm_forms input[type="radio"]');
    radiobuttons.forEach(radiobutton => {
        radiobutton.addEventListener('change', function () {
            $(".frm_radio").removeClass('grandparent-active');
            const grandparent = this.closest('.frm_radio');

            if (this.checked) {
                grandparent.classList.add('grandparent-active');
            } else {
                grandparent.classList.remove('grandparent-active');
            }
        });
    });
    const checkboxes = document.querySelectorAll('.frm_forms input[type="checkbox"]');
    checkboxes.forEach(checkbox => {
        checkbox.addEventListener('change', function () {
            const grandparent = this.closest('.frm_checkbox');

            if (this.checked) {
                grandparent.classList.add('grandparent-active');
            } else {
                grandparent.classList.remove('grandparent-active');
            }
        });
    });
});