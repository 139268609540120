import $ from 'jquery'

const $doc = $(document)
const $search = $('.js-search')
const $button = $('.js-search-open')
const $searchInput = $('.js-search-input')
const $form = $('.js-search-form')

function showSearch(event) {
    event.preventDefault()
    $button.addClass('is-active').attr('aria-expanded', 'true')
    $search.addClass('is-active')
    $form.addClass('is-active')
    setTimeout(() => $searchInput[0].focus(), 500)
}

function hideSearch() {
    $button.attr('aria-expanded', 'false').removeClass('is-active')
    $form.removeClass('is-active')
    $search.removeClass('is-active')
}

$doc.on('click', '.js-search-open:not(.is-active)', showSearch )
$doc.on('click', '.js-search-open.is-active', hideSearch )
$doc.on('keyup', function(event) {
  if ( $form.hasClass('is-active') && (event.key == "Escape") ) {
    hideSearch()
  }
})