import MicroModal from 'micromodal';  // es6 module
import $ from 'jquery'

$('.custom-modal').each(function () {
    var modalId = $(this).attr('id');
    // Initialize the modal with the fetched ID
    MicroModal.init({
        onShow: modal => console.info(`${modal.id} is shown`),
        onClose: modal => console.info(`${modal.id} is hidden`),
        openTrigger: 'data-custom-open',
        closeTrigger: 'data-custom-close',
        openClass: 'is-open',
        disableScroll: true,
        disableFocus: false,
        awaitOpenAnimation: false,
        awaitCloseAnimation: false,
        debugMode: true
    });
});