import $ from "jquery";
import "slick-carousel";

$(document).ready(initHomepageSlider);

const slickOptions = {
  centerMode: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  speed: 9000,
  autoplay: true,
  autoplaySpeed: 0,
  cssEase: "linear",
  arrows: false,
  dots: false,
  prevArrow:
    '<button type="button" class="slick-prev"><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="15" cy="15" r="14" transform="rotate(90 15 15)" stroke="#15325B" stroke-width="2"/><path d="M14.1684 8.34481L8.33266 14.1733C8.2264 14.2842 8.1431 14.4149 8.08755 14.558C7.97082 14.8418 7.97082 15.1602 8.08755 15.444C8.1431 15.5871 8.2264 15.7178 8.33265 15.8286L14.1684 21.6572C14.2772 21.7659 14.4064 21.8521 14.5486 21.9109C14.6908 21.9697 14.8432 22 14.9971 22C15.3079 22 15.606 21.8767 15.8258 21.6572C16.0455 21.4377 16.169 21.14 16.169 20.8295C16.169 20.5191 16.0455 20.2214 15.8258 20.0019L11.9742 16.1667L20.8328 16.1667C21.1424 16.1667 21.4393 16.0439 21.6581 15.8253C21.877 15.6067 22 15.3102 22 15.001C22 14.6918 21.877 14.3953 21.6581 14.1767C21.4393 13.9581 21.1424 13.8353 20.8328 13.8353L11.9742 13.8353L15.8258 10.0001C15.9352 9.89175 16.022 9.76282 16.0812 9.62077C16.1405 9.47872 16.171 9.32635 16.171 9.17246C16.171 9.01858 16.1405 8.86621 16.0812 8.72416C16.022 8.58211 15.9352 8.45318 15.8258 8.34481C15.7173 8.23555 15.5882 8.14883 15.446 8.08965C15.3037 8.03047 15.1512 8 14.9971 8C14.843 8 14.6905 8.03047 14.5482 8.08965C14.406 8.14883 14.2769 8.23555 14.1684 8.34481Z" fill="#15325B" /></svg></button>',
  nextArrow:
    '<button type="button" class="slick-next"><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="15" cy="15" r="14" transform="matrix(4.31309e-08 1 1 -4.42997e-08 0 0)" stroke="#15325B" stroke-width="2" /><path d="M15.8316 8.34481L21.6673 14.1733C21.7736 14.2842 21.8569 14.4149 21.9124 14.558C22.0292 14.8418 22.0292 15.1602 21.9124 15.444C21.8569 15.5871 21.7736 15.7178 21.6673 15.8286L15.8316 21.6572C15.7228 21.7659 15.5936 21.8521 15.4514 21.9109C15.3092 21.9697 15.1568 22 15.0029 22C14.6921 22 14.394 21.8767 14.1742 21.6572C13.9545 21.4377 13.831 21.14 13.831 20.8295C13.831 20.5191 13.9545 20.2214 14.1742 20.0019L18.0258 16.1667L9.16715 16.1667C8.8576 16.1667 8.56073 16.0439 8.34185 15.8253C8.12297 15.6067 8 15.3102 8 15.001C8 14.6918 8.12297 14.3953 8.34185 14.1767C8.56073 13.9581 8.8576 13.8353 9.16715 13.8353L18.0258 13.8353L14.1742 10.0001C14.0648 9.89175 13.978 9.76282 13.9188 9.62077C13.8595 9.47872 13.829 9.32635 13.829 9.17246C13.829 9.01858 13.8595 8.86621 13.9188 8.72416C13.978 8.58211 14.0648 8.45318 14.1742 8.34481C14.2827 8.23555 14.4118 8.14883 14.554 8.08965C14.6963 8.03047 14.8488 8 15.0029 8C15.157 8 15.3095 8.03047 15.4518 8.08965C15.594 8.14883 15.7231 8.23555 15.8316 8.34481Z" fill="#15325B" /></svg></button>',
  appendArrows: $(".home-hero__controls"),
  responsive: [
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

function initHomepageSlider() {
  if ($(".js-home-slider").length) {
    $(".js-home-slider").slick(slickOptions);
  }
}

function pauseVideos() {
  setTimeout(() => {
    $(".home-hero__slider video").each(function () {
      const video = $(this); // Current video element
      if (video.prop("autoplay") && !video.prop("paused")) {
        video.get(0).pause(); // Pause the video
      }
    });
    $("#heroPause").hide();
    $("#heroPlaySliderVideo").show();
  }, 100);
}

function playVideos() {
  $(".home-hero__slider video").each(function () {
    const video = $(this).get(0);
    if (video.paused) {
      video.play().catch((e) => console.log("Error playing video:", e));
    }
  });
  $("#heroPause").show();
  $("#heroPlaySliderVideo").hide();
}

$("#heroPause").on("click", () => {
  pauseVideos();
  const currentSlideIndex = $(".js-home-slider").slick("slickCurrentSlide");
  $(".js-home-slider").slick("unslick");
  slickOptions.autoplay = false;
  slickOptions.arrows = true;
  slickOptions.speed = 500;
  slickOptions.initialSlide = currentSlideIndex;
  $(".js-home-slider").slick(slickOptions);
});

$("#heroPlaySliderVideo").on("click", () => {
  $(".js-home-slider").slick("unslick");
  slickOptions.autoplay = true;
  slickOptions.speed = 9000;
  slickOptions.arrows = false;
  $(".js-home-slider").slick(slickOptions);
  playVideos();
});
