import "slick-carousel";
import $ from "jquery";

$(() => {
  const timelineSlider = $(".js-timeline-slider");

  if (!timelineSlider.length) {
    return;
  }

  timelineSlider
    .slick({
      arrows: true,
      dots: false,
      centerMode: true,
      infinite: false,
      slidesToShow: 5,
      focusOnSelect: true,
      variableWidth: true,
      initialSlide: 0,
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
    })
    .on("afterChange", function (event, slick, currentSlide) {
      setTimeout(() => {
        timelineSlider.slick("setPosition");
      }, 400);
    });
});
