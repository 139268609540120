import "slick-carousel";
import $ from "jquery";

$(() => {
  const impactSlider = $(".js-impact-slider");

  if (!impactSlider.length) {
    return;
  }

  impactSlider.slick({
    arrows: true,
    dots: false,
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    variableWidth: true,
    initialSlide: 0,
    prevArrow: '<button type="button" class="slick-prev"></button>',
    nextArrow: '<button type="button" class="slick-next"></button>',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },
    ],
  });
});
