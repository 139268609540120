import $ from 'jquery'

$(document).ready(initCardsSlider)

function initCardsSlider() {
    const sliderWrapper = $('.js-cards-slider');
    if (sliderWrapper.length) {
        sliderWrapper.slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            nextArrow: '<button type="button" class="slick-next"><svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M23.4655 40.7337L40.7695 23.4512C41.0846 23.1224 41.3315 22.7348 41.4963 22.3105C41.8424 21.469 41.8424 20.5251 41.4963 19.6836C41.3315 19.2593 41.0846 18.8717 40.7695 18.5429L23.4655 1.26042C23.1429 0.938144 22.7598 0.682499 22.3382 0.508083C21.9166 0.333666 21.4647 0.243894 21.0084 0.243894C20.0868 0.243894 19.2029 0.609551 18.5512 1.26042C17.8996 1.91129 17.5334 2.79407 17.5334 3.71454C17.5334 4.63501 17.8996 5.51778 18.5512 6.16865L29.9718 17.5405L3.70445 17.5405C2.7866 17.5405 1.90633 17.9047 1.2573 18.5529C0.608282 19.2011 0.243666 20.0803 0.243666 20.997C0.243665 21.9138 0.608282 22.7929 1.2573 23.4412C1.90633 24.0894 2.7866 24.4535 3.70445 24.4535L29.9718 24.4535L18.5512 35.8254C18.2269 36.1468 17.9694 36.5291 17.7937 36.9503C17.618 37.3715 17.5275 37.8233 17.5275 38.2796C17.5275 38.7358 17.618 39.1876 17.7937 39.6088C17.9694 40.03 18.2269 40.4123 18.5512 40.7337C18.873 41.0576 19.2557 41.3148 19.6774 41.4903C20.0992 41.6657 20.5515 41.7561 21.0084 41.7561C21.4653 41.7561 21.9176 41.6657 22.3393 41.4903C22.7611 41.3148 23.1438 41.0576 23.4655 40.7337Z"/></svg></button>',
            prevArrow: '<button type="button" class="slick-prev"><svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M18.5345 40.7337L1.23051 23.4512C0.915442 23.1224 0.668463 22.7348 0.503749 22.3105C0.157607 21.469 0.157607 20.5251 0.503748 19.6836C0.668462 19.2593 0.915442 18.8717 1.23051 18.5429L18.5345 1.26042C18.8571 0.938144 19.2402 0.682499 19.6618 0.508083C20.0834 0.333666 20.5353 0.243894 20.9916 0.243894C21.9132 0.243894 22.7971 0.609551 23.4488 1.26042C24.1004 1.91129 24.4666 2.79407 24.4666 3.71454C24.4666 4.63501 24.1004 5.51778 23.4488 6.16865L12.0282 17.5405L38.2955 17.5405C39.2134 17.5405 40.0937 17.9047 40.7427 18.5529C41.3917 19.2011 41.7563 20.0803 41.7563 20.997C41.7563 21.9138 41.3917 22.7929 40.7427 23.4412C40.0937 24.0894 39.2134 24.4535 38.2955 24.4535L12.0282 24.4535L23.4488 35.8254C23.7731 36.1468 24.0306 36.5291 24.2063 36.9503C24.382 37.3715 24.4725 37.8233 24.4725 38.2796C24.4725 38.7358 24.382 39.1876 24.2063 39.6088C24.0306 40.03 23.7731 40.4123 23.4488 40.7337C23.127 41.0576 22.7443 41.3148 22.3226 41.4903C21.9008 41.6657 21.4485 41.7561 20.9916 41.7561C20.5347 41.7561 20.0824 41.6657 19.6607 41.4903C19.2389 41.3148 18.8562 41.0576 18.5345 40.7337Z"/></svg></button>',
            responsive: [
                {
                    breakpoint: 960,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
    }
}
